import React, { useState } from 'react'
import { Flex, Text } from '../primitives'
import { BackAndNext } from './components/BackAndNext'
import { OptionCard } from './components/OptionCard'
import { Check } from 'styled-icons/boxicons-regular'
import { addOptionToOrder } from '../../api/addOptionToOrder'
import { conceptPrice, guidancePrice } from '../../helpers/config'

const Item = ({ title }) => {
  return (
    <Text>
      <Check size='25px' style={{ marginBottom: '5px', marginRight: '5px' }} />
      {title}
    </Text>
  )
}

export const Option = ({ orderId, backClick, next, option }) => {
  const [selectedOne, setSelectedOne] = useState(option === 'guidance')
  const [selectedTwo, setSelectedTwo] = useState(option === 'concept')
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <Flex
        width={'100%'}
        flexDirection={'column'}
        flexWrap={'wrap'}
        flex={1}
        style={{ overflowY: 'auto', alignContent: 'flex-start' }}>
        <Flex width={'100%'} flexWrap={'wrap'} jusifyContent={'center'}>
          <OptionCard
            title={'Rådgivning'}
            price={guidancePrice}
            selected={selectedOne}
            onClick={() => {
              setSelectedOne(true)
              setSelectedTwo(false)
            }}>
            <Flex flexDirection={'column'} width={'100%'}>
              <Text textAlign={'center'} mb={2}>
                Få snabba tips kring din inredning av en professionell inredare
                som tar fram en digital shoppinglista till dig.
              </Text>
              <Item title='30 min samtal med inredare per rum' />
              <Item title='Digital shoppinglista' />
            </Flex>
          </OptionCard>
          <OptionCard
            title={'Koncept'}
            selected={selectedTwo}
            price={conceptPrice}
            onClick={() => {
              setSelectedOne(false)
              setSelectedTwo(true)
            }}>
            <Flex flexDirection={'column'} width={'100%'}>
              <Text pr={2} pl={2} textAlign={'center'} mb={2}>
                Få ett helhetskoncept kring ditt rum av en professionell
                inredare som arbetar fram känsla, stil och möblering anpassat
                efter dina behov och önskemål.
              </Text>
              <Item title='30 min samtal med inredare per rum' />
              <Item title='Moodboard' />
              <Item title='Planskiss' />
              <Item title='Digital shoppinglista' />
              <Item title='Feedback genom hela processen' />
              <Item title='Uppdatering av konceptet en vända' />
            </Flex>
          </OptionCard>
        </Flex>
      </Flex>
      <BackAndNext
        canClickNext={selectedOne || selectedTwo}
        isLoading={isLoading}
        backClick={backClick}
        nextClick={async () => {
          if (selectedOne || selectedTwo) {
            setIsLoading(true)
            let option = 'concept'
            if (selectedOne) {
              option = 'guidance'
            }
            await addOptionToOrder({ orderId: orderId, option: option })
            setIsLoading(false)
            next(option)
          }
        }}
      />
    </>
  )
}
