import { Flex } from '../../primitives'
import { ButtonWhite } from '../../shareable/buttons/ButtonWhite'
import { ButtonPrimaryLight } from '../../shareable/buttons/ButtonPrimaryLight'
import React from 'react'
import { Loading } from '../../shareable/loading/loading'
import { ButtonPrimary } from '../../shareable/buttons/ButtonPrimary'

export const BackAndNext = ({
  canClickNext,
  backClick,
  nextClick,
  disableClick,
  isLoading,
  backTitle = 'Bakåt',
  nextTitle = 'Nästa'
}) => {
  return (
    <>
      <Flex
        display={['none', 'none', 'flex']}
        width={'100%'}
        pb={[0, 0, 5]}
        alignItems={'flex-end'}
        jusifyContent={'space-between'}>
        <ButtonWhite onClick={backClick}>{backTitle}</ButtonWhite>
        <Loading isLoading={isLoading} mb={1}>
          {canClickNext ? (
            <ButtonPrimary onClick={nextClick}>{nextTitle}</ButtonPrimary>
          ) : (
            <ButtonPrimaryLight
              onClick={() => {
                if (disableClick) {
                  disableClick()
                }
              }}>
              {nextTitle}
            </ButtonPrimaryLight>
          )}
        </Loading>
      </Flex>

      <Flex
        display={['flex', 'flex', 'none']}
        width={'100%'}
        bg={'white'}
        pt={2}
        pb={[0, 0, 8]}
        jusifyContent={'space-between'}>
        <ButtonWhite minWidth={'60px'} onClick={backClick}>
          {backTitle}
        </ButtonWhite>
        <Loading isLoading={isLoading} mb={1}>
          {canClickNext ? (
            <ButtonPrimary minWidth={'60px'} onClick={nextClick}>
              {nextTitle}
            </ButtonPrimary>
          ) : (
            <ButtonPrimaryLight
              minWidth={'60px'}
              onClick={() => {
                if (disableClick) {
                  disableClick()
                }
              }}>
              {nextTitle}
            </ButtonPrimaryLight>
          )}
        </Loading>
      </Flex>
    </>
  )
}
