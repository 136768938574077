import axios from 'axios'
import { ShowError } from '../helpers/showError'

export const startOrder = async () => {
  try {
    const url = process.env.API_URL + '/startOrder'
    const response = await axios({
      method: 'post',
      url: url,
      headers: {
        'content-type': 'application/json'
      }
    })

    return response ? response.data : null
  } catch (e) {
    console.log('erororo', e.response.data)
    ShowError({
      message: 'Kunde inte skapa ordern. Prova igen lite senare'
    })
  }
}
