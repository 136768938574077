import React, { useState } from 'react'
import { Flex, Text } from '../../primitives'

export const ButtonPicker = ({
  titleOne,
  titleTwo,
  onSelected,
  initalOneValue = true,
  mb = [8, 5, 2]
}) => {
  const [oneSelected, setOneSelected] = useState(initalOneValue)
  return (
    <Flex height={'40px'} alignItems={'center'} mb={mb}>
      <Flex
        onClick={() => {
          setOneSelected(true)
          onSelected('one')
        }}
        style={{
          cursor: 'pointer',
          zIndex: oneSelected ? 1000 : 0,
          boxShadow: oneSelected ? '0px 6px 16px -4px rgba(0,0,0,0.22)' : 'none'
        }}
        bg={oneSelected ? 'white' : 'grey'}
        height={'40px'}
        color={oneSelected ? 'black' : 'secondaryLight'}
        width={'80px'}
        alignItems={'center'}
        jusifyContent={'center'}>
        <Text>{titleOne}</Text>
      </Flex>
      <Flex
        onClick={() => {
          setOneSelected(false)
          onSelected('two')
        }}
        style={{
          cursor: 'pointer',
          zIndex: oneSelected ? 0 : 1000,
          boxShadow: oneSelected ? 'none' : '0px 6px 16px -4px rgba(0,0,0,0.22)'
        }}
        bg={oneSelected ? 'grey' : 'white'}
        color={oneSelected ? 'secondaryLight' : 'black'}
        height={'40px'}
        width={'80px'}
        alignItems={'center'}
        jusifyContent={'center'}>
        <Text>{titleTwo}</Text>
      </Flex>
    </Flex>
  )
}
