// src/layouts/index.js
import React, { useRef, useState, useEffect } from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'
import Modal from 'react-modal'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import '../../css/modal.css'
import '../../css/fixblogimg.css'
import theme from '../../other/theme'
import { Navbar } from '../shareable/navbar/Navbar'
import { Order } from '../order/Order'
import { Helmet } from 'react-helmet'
import { Question } from '../shareable/sections/Question'
import { parse } from 'query-string'

const GlobalStyle = createGlobalStyle`
      * {
      font-family: futura-pt, futura, sans-seriff;
    }

#klarna-checkout-container{
width: 340px;

}

@media only screen and (max-width: 960px) {
 #klarna-checkout-container{
width: 100%;

}

}
`

Modal.setAppElement('#___gatsby')

const Layout = ({
  children,
  headTitle = 'Hemset.se',
  metaDesc = '',
  modelOpenProps,
  onCloseModal,
  blackNav = false,
  location,
  navigate
}) => {
  const modalRef = useRef(null)

  const [showSaveProgress, setShowSaveProgress] = useState(false)
  const [modalIsOpen, setIsModalOpen] = useState(false)
  const [orderId, setOrderId] = useState(null)

  useEffect(() => {
    if (location && location.search) {
      const parsed = parse(location.search)
      console.log(parsed)
      if (parsed.orderId) {
        setOrderId(parsed.orderId)
        setIsModalOpen(true)
      }
    }
  }, [location])

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <meta charSet='utf-8' />
          <title>{headTitle}</title>
          <meta name='description' content={metaDesc} />

          <link
            href='https://calendly.com/assets/external/widget.css'
            rel='stylesheet'
          />
          <script
            type='text/javascript'
            src='https://assets.calendly.com/assets/external/widget.js'
          />
        </Helmet>
        <Normalize />
        <GlobalStyle />

        <Question />
        <Navbar
          blackNav={blackNav}
          modalOpen={() => {
            setIsModalOpen(true)
          }}
        />

        <Modal
          ref={modalRef}
          isOpen={modalIsOpen || modelOpenProps}
          onAfterOpen={() => {}}
          onRequestClose={() => {
            if (showSaveProgress) {
              if (onCloseModal) {
                onCloseModal()
              }
              setIsModalOpen(false)
              setShowSaveProgress(false)
            } else {
              setShowSaveProgress(true)
            }
          }}
          className={'Hemset_ReactModal__Content'}
          style={{
            overlay: {
              backgroundColor: 'transparent'
            }
          }}
          contentLabel=''>
          <Order
            navigate={navigate}
            orderId={orderId}
            showSaveProgress={showSaveProgress}
            onClose={() => {
              if (showSaveProgress) {
                if (onCloseModal) {
                  onCloseModal()
                }
                setIsModalOpen(false)
                setShowSaveProgress(false)
              } else {
                setShowSaveProgress(true)
              }
            }}
          />
        </Modal>
        <ReactNotification />

        {children}
      </>
    </ThemeProvider>
  )
}

export default Layout
