import React, { useEffect, useState } from 'react'
import { Flex, Text } from '../primitives'
import { CrossButton } from '../shareable/buttons/CrossButton'
import { FileStep } from './FileStep'
import { ChooseFurnishings } from './ChooseFurnishings'
import { ChooseRoom } from './ChooseRoom'
import { Budget } from './Budget'
import { Start } from './Start'
import { Basket } from './Basket'
import { SaveOrder } from './SaveOrder'
import { getNotPaidOrder } from '../../api/getNotPaidOrder'
import { Loading } from '../shareable/loading/loading'
import { Option } from './Option'

const Step = ({ selected }) => {
  return (
    <Flex
      bg={selected ? 'primary' : 'grey'}
      height={selected ? '4px' : '2px'}
      flex={1}
    />
  )
}
const Stepper = ({ step }) => {
  if (step === 1) {
    return null
  }
  return (
    <Flex
      width={'100%'}
      flexDirection={'column'}
      alignItems={'center'}
      jusifyContent={'center'}>
      <Flex mb={[1, 1, 4]}>
        <Text color={'black'} mr={1}>
          {step - 1}
        </Text>
        <Text color={'lightGrey'} mr={1}>
          /
        </Text>
        <Text color={'lightGrey'}>5</Text>
      </Flex>
      <Flex width={'100%'}>
        <Step selected={step > 0} />
        <Step selected={step > 1} />
        <Step selected={step > 2} />
        <Step selected={step > 3} />
        <Step selected={step > 4} />
        <Step selected={step > 5} />
      </Flex>
    </Flex>
  )
}

const getTitle = (pageIndex) => {
  if (pageIndex === 0) {
    return ''
  } else if (pageIndex === 1) {
    return ''
  } else if (pageIndex === 2) {
    return 'Vilket rum vill du inreda?'
  } else if (pageIndex === 3) {
    return 'Vilka bilder tilltalar dig mest?'
  } else if (pageIndex === 4) {
    return 'Filma rummet'
  } else if (pageIndex === 5) {
    return 'Vad är din budget?'
  } else if (pageIndex === 6) {
    return 'Kontaktuppgifter'
  }
}

export const Order = ({ onClose, orderId, showSaveProgress, navigate }) => {
  const [pageIndex, setPageIndex] = useState(0)
  const [order, setOrder] = useState(null)
  const [checkout, setCheckout] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (orderId) {
      setLoading(true)
      getNotPaidOrder({ orderId: orderId })
        .then((data) => {
          setLoading(false)
          console.log(data)
          if (data && data.order) {
            if (data.order.customerOrder.klarnaId) {
              navigate(
                '/checkout?klarnaId=' + data.order.customerOrder.klarnaId
              )
            } else {
              let o = {}
              let pIndex = 0

              console.log('daasd', data.order)
              if (data.order.customerOrder.productOption) {
                o.productOption = data.order.customerOrder.productOption
                pIndex = 1
              }
              if (data.order.customerOrder.rooms) {
                o.rooms = data.order.customerOrder.rooms
                pIndex = 2
              }
              if (data.order.customerOrder.whatAppeal) {
                o.whatAppeal = data.order.customerOrder.whatAppeal
                pIndex = 3
              }
              if (data.order.customerOrder.comment) {
                o.comment = data.order.customerOrder.comment
                pIndex = 4
              }
              if (data.order.customerOrder.pinterest) {
                o.pinterest = data.order.customerOrder.pinterest
                pIndex = 4
              }
              if (
                data.order.customerOrder.files &&
                data.order.customerOrder.files.length > 0
              ) {
                o.files = data.order.customerOrder.files
                pIndex = 4
              }
              if (data.order.customerOrder.budget) {
                o.budget = data.order.customerOrder.budget
                pIndex = 5
              }
              if (data.order.customerOrder.budgetType) {
                o.budgetType = data.order.customerOrder.budgetType
                pIndex = 5
              }

              if (o.productOption) {
                setOrder({ id: orderId, ...o })
                setPageIndex(pIndex)
              }
            }
          }
        })
        .catch((e) => {
          setLoading(false)
        })
    }
  }, [orderId])

  if (loading) {
    return (
      <Flex
        bg={'white'}
        width={['100%', '100%', '890px']}
        height={['93%', '93%', '90vh']}
        flexDirection={'column'}
        alignItems={'center'}
        style={{ overflow: 'hidden' }}>
        <Loading isLoading={true} />
      </Flex>
    )
  }

  return (
    <Flex
      bg={'white'}
      width={['100%', '100%', '890px']}
      height={['93%', '93%', '90vh']}
      flexDirection={'column'}
      alignItems={'center'}
      style={{ overflow: 'hidden' }}>
      <Flex
        style={{ alignSelf: 'flex-end' }}
        pt={[0, 0, 2]}
        pr={[0, 0, 2]}
        pb={[2, 2, 0]}>
        <CrossButton onClick={onClose} />
      </Flex>
      {showSaveProgress ? (
        <SaveOrder orderId={order ? order.id : 'noid'} />
      ) : (
        <Flex
          width={'100%'}
          maxWidth={pageIndex === 6 ? '730px' : '590px'}
          height={'100%'}
          flexDirection={'column'}
          alignItems={'center'}
          style={{ overflow: 'hidden' }}>
          {pageIndex === 0 && (
            <Start
              orderId={order ? order.id : null}
              start={(orderId) => {
                setOrder({ ...order, id: orderId })
                setPageIndex(pageIndex + 1)
              }}
              close={onClose}
            />
          )}

          {pageIndex === 6 && (
            <Basket
              changeRoom={(rooms) => {
                setOrder({ ...order, rooms: rooms })
                if (rooms.length === 0) {
                  setPageIndex(1)
                }
              }}
              changeOption={(option) => {
                setOrder({ ...order, productOption: option })
              }}
              checkout={checkout}
              rooms={order.rooms}
              orderId={order.id}
              productOption={order.productOption}
              backClick={() => {
                setPageIndex(pageIndex - 1)
              }}
            />
          )}

          {pageIndex !== 0 && pageIndex !== 6 && (
            <>
              <Stepper step={pageIndex} />
              <Text
                textAlign={'start'}
                width={'100%'}
                mt={[4, 4, 7]}
                mb={[2, 2, 4]}
                fontSize={'22px'}>
                {getTitle(pageIndex)}
              </Text>

              <>
                {pageIndex === 1 && (
                  <Option
                    option={
                      order && order.productOption ? order.productOption : ''
                    }
                    backClick={() => {
                      setPageIndex(pageIndex - 1)
                    }}
                    orderId={order.id}
                    next={(option) => {
                      setOrder({ ...order, productOption: option })
                      setPageIndex(pageIndex + 1)
                    }}
                  />
                )}
                {pageIndex === 2 && (
                  <ChooseRoom
                    rooms={order && order.rooms ? order.rooms : []}
                    backClick={() => {
                      setPageIndex(pageIndex - 1)
                    }}
                    orderId={order.id}
                    next={(rooms) => {
                      setOrder({ ...order, rooms: rooms })
                      setPageIndex(pageIndex + 1)
                    }}
                  />
                )}

                {pageIndex === 3 && (
                  <ChooseFurnishings
                    backClick={() => {
                      setPageIndex(pageIndex - 1)
                    }}
                    whatAppeal={order.whatAppeal}
                    orderId={order.id}
                    next={(whatAppeal) => {
                      setOrder({ ...order, whatAppeal: whatAppeal })
                      setPageIndex(pageIndex + 1)
                    }}
                  />
                )}
                {pageIndex === 4 && (
                  <FileStep
                    initial={order.files ? order.files : []}
                    pinterest={order.pinterest}
                    comment={order.comment}
                    backClick={() => {
                      setPageIndex(pageIndex - 1)
                    }}
                    orderId={order.id}
                    next={(files) => {
                      console.log(files)
                      setOrder({ ...order, files: files })
                      setPageIndex(pageIndex + 1)
                    }}
                  />
                )}
                {pageIndex === 5 && (
                  <Budget
                    backClick={() => {
                      setPageIndex(pageIndex - 1)
                    }}
                    initalType={order.budgetType}
                    initialPrice={order.budget}
                    rooms={order.rooms}
                    orderId={order.id}
                    next={(c) => {
                      setCheckout(c)
                      setPageIndex(pageIndex + 1)
                    }}
                  />
                )}
              </>
            </>
          )}
        </Flex>
      )}
    </Flex>
  )
}
