import axios from 'axios'
import { ShowError, ShowInfo } from "../helpers/showError"

export const addDiscountCodeToOrder = async ({ orderId, discountCode }) => {
  try {
    const url = process.env.API_URL + '/addDiscountCodeToOrder'
    const response = await axios({
      method: 'post',
      url: url,
      data: {
        orderId,
        discountCode
      },
      headers: {
        'content-type': 'application/json'
      }
    })

    return response.data
  } catch (e) {
    console.log('erororo', e.response.status)
    if (e.response.status === 404) {
      ShowInfo({
        message: 'Kunde inte hitta rabattkod, prova med en annan'
      })
    } else {
      ShowError({
        message: 'Kunde inte lägga till rabattkoden. Prova igen lite senare'
      })
    }
  }
}
