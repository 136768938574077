import axios from 'axios'
import { ShowError } from '../helpers/showError'

export const addOptionToOrder = async ({ orderId, option }) => {
  try {
    const url = process.env.API_URL + '/addOptionToOrder'
    const response = await axios({
      method: 'post',
      url: url,
      data: {
        orderId,
        productOption: option
      },
      headers: {
        'content-type': 'application/json'
      }
    })

    return response.data
  } catch (e) {
    console.log('erororo', e.response.data)
    ShowError({
      message: 'Kunde inte skapa order. Prova igen lite senare'
    })
  }
}
