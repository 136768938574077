import React, { useLayoutEffect, useState } from 'react'
import { Flex, ImageContain, Text } from '../../primitives'
import logo from '../../../images/hemset-white.png'
import klarna from '../../../images/Klarna_PaymentBadge_OutsideCheckout_White.svg'
import ig from '../../../images/ig.png'
import fb from '../../../images/fb.png'
import { Link } from 'gatsby'
import { TextWithClick } from '../text/TextWithHover'
import Modal from 'react-modal'
import { Input } from '../form/Input'
import { ButtonPrimary } from '../buttons/ButtonPrimary'
import { CrossButton } from '../buttons/CrossButton'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { Loading } from '../loading/loading'
import { useCookies } from 'react-cookie'

const mcUrl =
  'https://dedecco.us18.list-manage.com/subscribe/post?u=b3193a9b412e229fcff407200&amp;id=8ff05d94fd'

const ALink = ({ title, to, ...props }) => (
  <Link style={{ textDecoration: 'none' }} to={to} {...props}>
    <TextWithClick color={'white'}>{title}</TextWithClick>
  </Link>
)

const desktop = ({ actionClick, openNewsSub }) => {
  return (
    <Flex
      display={['none', 'none', 'flex']}
      height={'380px'}
      pr={'80px'}
      pl={'80px'}
      pb={'100px'}
      pt={'100px'}
      bg={'#9a7652'}>
      <Flex
        flex={1}
        flexDirection={'column'}
        height={'100%'}
        jusifyContent={'space-between'}>
        <ImageContain height={'55px'} width={'130px'} src={logo} />

        <Text color={'white'} width={'70%'}>
          Få ett trivsamt hem med Hemset. Oavsett var du bor, hur mycket tid du
          har och vilken budget du sätter så hjälper vi dig att inreda så att du
          kan längta hem.
        </Text>
      </Flex>
      <Flex
        style={{ borderLeft: '1px solid rgba(255, 255, 255, .5)' }}
        alignItems={'center'}
        flex={1}
        flexDirection={'column'}
        height={'100%'}
        jusifyContent={'space-between'}>
        <ALink title={'Hem'} to={'/'} />
        <TextWithClick onClick={actionClick} color={'white'}>
          Lägg beställning
        </TextWithClick>
        <ALink title={'Blogg'} to={'/blog'} />
        <ALink title={'#hemset'} to={'/hemset'} />
        <ALink title={'Om oss'} to={'/about'} />
        <TextWithClick
          color={'white'}
          onClick={() => {
            window.open('https://admin.hemset.se/apply-decorator', '_blank')
          }}>
          Ansök om att bli inredare
        </TextWithClick>
      </Flex>
      <Flex
        style={{ borderLeft: '1px solid rgba(255, 255, 255, .5)' }}
        flex={1}
        flexDirection={'column'}
        alignItems={'center'}
        height={'100%'}
        jusifyContent={'space-between'}>
        <ALink title={'Allmänna villkor'} to={'/terms'} />
        <ALink title={'Integritetspolicy'} to={'/privacypolicy'} />
        <ALink title={'Cookiepolicy'} to={'/cookie'} />

        <TextWithClick
          color={'white'}
          onClick={() => {
            openNewsSub()
          }}>
          Nyhetsbrev
        </TextWithClick>

        <ALink title={'FAQ'} to={'/faq'} />
        <ALink title={'Hållbarhet'} to={'/sustainability'} />
      </Flex>
      <Flex
        style={{ borderLeft: '1px solid rgba(255, 255, 255, .5)' }}
        flex={1}
        alignItems={'center'}
        flexDirection={'column'}
        height={'100%'}
        jusifyContent={'space-between'}>
        <Flex>
          <a
            target="_blank"
            style={{ textDecoration: 'none' }}
            href={'https://www.instagram.com/hemsetcom/'}>
            <ImageContain mr={2} height={'40px'} width={'40px'} src={ig} />
          </a>
          <a
            target="_blank"
            style={{ textDecoration: 'none' }}
            href={'https://www.facebook.com/hemsetcom/'}>
            <ImageContain ml={2} height={'40px'} width={'40px'} src={fb} />
          </a>
        </Flex>
        <a
          target="_blank"
          style={{ visibility: 'hidden' }}
          title={'Instagram'}
          to={'https://www.instagram.com/hemsetcom/'}
        />

        <ImageContain height={'30px'} width={'100px'} src={klarna} />
        <Text color={'white'}>© 2019 Copyright Hemset AB</Text>
      </Flex>
    </Flex>
  )
}

const mobile = ({ actionClick, openNewsSub }) => {
  return (
    <Flex
      display={['flex', 'flex', 'none']}
      flexDirection={'column'}
      alignItems={'center'}
      jusifyContent={'center'}
      bg={'#9a7652'}>
      <Flex
        mb={6}
        mt={6}
        flexDirection={'column'}
        alignItems={'center'}
        jusifyContent={'center'}>
        <ImageContain height={'75px'} width={'160px'} src={logo} />

        <Text pr={4} pl={4} mb={5} textAlign={'center'} color={'white'}>
          Få ett trivsamt hem med Hemset. Oavsett var du bor, hur mycket tid du
          har och vilken budget du sätter så hjälper vi dig att inreda så att du
          kan längta hem.
        </Text>
      </Flex>
      <Flex
        style={{ borderTop: '1px solid rgba(255, 255, 255, .5)' }}
        alignItems={'center'}
        width={'100%'}
        height={'200px'}
        flexDirection={'column'}
        pt={2}
        pb={2}
        jusifyContent={'space-around'}>
        <ALink title={'Hem'} to={'/'} />
        <TextWithClick onClick={actionClick} color={'white'}>
          Lägg beställning
        </TextWithClick>
        <ALink title={'Blogg'} to={'/blog'} />
        <ALink title={'#hemset'} to={'/hemset'} />
        <ALink title={'Om oss'} to={'/about'} />
        <TextWithClick
          color={'white'}
          onClick={() => {
            window.open('https://admin.hemset.se/apply-decorator', '_blank')
          }}>
          Ansök om att bli inredare
        </TextWithClick>
      </Flex>
      <Flex
        style={{ borderTop: '1px solid rgba(255, 255, 255, .5)' }}
        alignItems={'center'}
        width={'100%'}
        pt={2}
        pb={2}
        height={'180px'}
        flexDirection={'column'}
        jusifyContent={'space-around'}>
        <ALink title={'Allmänna villkor'} to={'/terms'} />
        <ALink title={'Integritetspolicy'} to={'/privacypolicy'} />
        <ALink title={'Cookiepolicy'} to={'/cookie'} />
        <TextWithClick
          color={'white'}
          onClick={() => {
            openNewsSub()
          }}>
          Nyhetsbrev
        </TextWithClick>
        <ALink title={'FAQ'} to={'/faq'} />
        <ALink title={'Hållbarhet'} to={'/sustainability'} />
      </Flex>
      <Flex
        style={{ borderTop: '1px solid rgba(255, 255, 255, .5)' }}
        alignItems={'center'}
        width={'100%'}
        height={'100px'}
        pt={2}
        pb={2}
        flexDirection={'column'}
        jusifyContent={'space-around'}>
        <Flex>
          <a
            style={{ textDecoration: 'none' }}
            href={'https://www.instagram.com/hemsetcom/'}>
            <ImageContain mr={2} height={'40px'} width={'40px'} src={ig} />
          </a>
          <a
            style={{ textDecoration: 'none' }}
            href={'https://www.facebook.com/hemsetcom/'}>
            <ImageContain ml={2} height={'40px'} width={'40px'} src={fb} />
          </a>
        </Flex>
      </Flex>

      <Flex
        style={{ borderTop: '1px solid rgba(255, 255, 255, .5)' }}
        alignItems={'center'}
        width={'100%'}
        pt={4}
        pb={4}
        flexDirection={'column'}
        jusifyContent={'space-around'}>
        <ImageContain height={'30px'} width={'100px'} src={klarna} />
        <Text mt={4} color={'white'}>
          © 2019 Copyright Hemset AB
        </Text>
      </Flex>
    </Flex>
  )
}

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function addDays(date, days) {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const Footer = ({ actionClick }) => {
  const [modalIsOpen, setIsModalOpen] = useState(false)

  const [cookies, setCookie] = useCookies(['news-popup'])

  const [isSuccess, setIsSuccess] = useState(false)
  const [email, setEmail] = useState('')

  useLayoutEffect(() => {
    timeout(20000).then(() => {
      if (!cookies['news-popup']) {
        setIsModalOpen(true)
        setCookie('news-popup', true, { expires: addDays(new Date(), 14) })
      }
    })
    // eslint-disable-next-line
  }, [])
  const openNewsSub = () => {
    setIsModalOpen(true)
  }
  return (
    <>
      <Modal
        onAfterOpen={() => {}}
        onRequestClose={() => {
          setIsModalOpen(false)
        }}
        className={'Hemset_ReactModal__Content_2'}
        bodyOpenClassName={'ReactModal__Body--open-2'}
        style={{
          overlay: {
            backgroundColor: 'transparent'
          }
        }}
        contentLabel=''
        isOpen={modalIsOpen}>
        <Flex
          p={5}
          width={['100%', '100%', '50vw']}
          bg={'white'}
          flexDirection={'column'}>
          <Flex
            style={{ alignSelf: 'flex-end' }}
            pt={[0, 0, 2]}
            pr={[0, 0, 2]}
            pb={[2, 2, 0]}>
            <CrossButton
              onClick={() => {
                setIsModalOpen(false)
              }}
            />
          </Flex>
          <Text fontSize={7}>Nyhetsbrev</Text>
          <Text mt={4}>
            Få uppdateringar med tips kring livstil, inredning och annat
            spännande innehåll!
          </Text>
          <MailchimpSubscribe
            url={mcUrl}
            render={({ subscribe, status, message }) => {
              if (status === 'success') {
                if (!isSuccess) {
                  console.log('success')
                  window.dataLayer = window.dataLayer || []
                  window.dataLayer.push({
                    event: 'Prenumerera'
                  })
                }
                setIsSuccess(true)
              }
              return (
                <div>
                  <Flex width={'300px'} mt={4}>
                    <Input
                      placeholder={'E-mail'}
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                    />
                  </Flex>
                  <Flex>
                    <Loading isLoading={status === 'sending'}>
                      <ButtonPrimary
                        mt={2}
                        onClick={() => {
                          subscribe({ EMAIL: email })
                        }}
                        type={'submit'}>
                        Prenumerera
                      </ButtonPrimary>
                    </Loading>
                  </Flex>

                  {status === 'error' && (
                    <div
                      style={{ color: 'red' }}
                      dangerouslySetInnerHTML={{ __html: message }}
                    />
                  )}
                  {status === 'success' && (
                    <div style={{ color: 'green' }}>Sparat!</div>
                  )}
                </div>
              )
            }}
          />
        </Flex>
      </Modal>
      {desktop({ actionClick, openNewsSub })}{' '}
      {mobile({ actionClick, openNewsSub })}
    </>
  )
}
