import axios from 'axios'
import { ShowError } from '../helpers/showError'

export const createCheckout = async ({ rooms, orderId }) => {
  try {
    const url = process.env.API_URL + '/createCheckout'
    const response = await axios({
      method: 'post',
      url: url,
      data: {
        rooms,
        orderId
      },
      headers: {
        'content-type': 'application/json'
      }
    })

    console.log(response.data)
    return response.data
  } catch (e) {
    console.log('erororo', e.response.data)
    ShowError({
      message: 'Kunde inte skapa betalningen. Prova igen lite senare'
    })
  }
}
