import { Flex, Text } from '../../primitives'
import { TextWithClick } from '../../shareable/text/TextWithHover'
import { ChevronDown } from 'styled-icons/evil'
import { Input } from '../../shareable/form/Input'
import { Loading } from '../../shareable/loading/loading'
import { ButtonPrimaryLight } from '../../shareable/buttons/ButtonPrimaryLight'
import { addCommentToOrder } from '../../../api/addCommentToOrder'
import React, { useState } from 'react'

export const AddComment = ({ orderId, commentAlreadySaved = false }) => {
  //const [showComment, setShowComment] = useState(true)
  const [addCommentLoading, setAddCommentLoading] = useState(false)
  const [comment, setComment] = useState('')
  const [commentIsSaved, setCommentIsSaved] = useState(commentAlreadySaved)

  return (
    <Flex flexDirection={'column'} p={4} >
      {/*<Flex flexDirection={'column'}>
        <Flex mt={4} mb={4} jusifyContent={'space-between'}>
          <Flex
            onClick={() => {
              setShowComment(!showComment)
            }}>
            <TextWithClick mr={1} style={{ textDecoration: 'underline' }}>
              Lämna en kommentar
            </TextWithClick>

            <ChevronDown size={'20px'} />
          </Flex>
          <Text>(ex vilka färger du gillar)</Text>
        </Flex>
      </Flex>*/}

      <Flex flexDirection={'column'}>
        {commentIsSaved && <Text mb={2}>Kommentar sparad!</Text>}
        <Flex>
          <Input
            placeholder={'Kommentar'}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value)
            }}
          />
        </Flex>
        <Flex mt={2} jusifyContent={'flex-end'}>
          <Loading isLoading={addCommentLoading}>
            <ButtonPrimaryLight
              onClick={async () => {
                setAddCommentLoading(true)
                const c = await addCommentToOrder({
                  orderId: orderId,
                  comment: comment
                })
                if (c) {
                  setCommentIsSaved(true)
                }
                setAddCommentLoading(false)
                setComment('')
              }}
              minWidth={'50px'}>
              Spara
            </ButtonPrimaryLight>
          </Loading>
        </Flex>
      </Flex>
    </Flex>
  )
}
