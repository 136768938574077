import React, { useLayoutEffect, useRef, useState } from 'react'
import { Flex, ImageContain, Text } from '../primitives'
import { TextWithClick } from '../shareable/text/TextWithHover'
import { ButtonWhite } from '../shareable/buttons/ButtonWhite'
import style1 from '../../images/Arbetsrum.svg'
import style2 from '../../images/Balkong.svg'
import style3 from '../../images/Hall.svg'
import style4 from '../../images/Kök.svg'
import style5 from '../../images/Konferansrum.svg'
import style6 from '../../images/Sovrum.svg'
import style7 from '../../images/Vardagsrum.svg'
import { Close } from 'styled-icons/evil/Close'
import { ChevronDown } from 'styled-icons/evil/ChevronDown'
import { Input } from '../shareable/form/Input'
import { Loading } from '../shareable/loading/loading'
import { ButtonPrimaryLight } from '../shareable/buttons/ButtonPrimaryLight'
import { addRoomToOrder } from '../../api/addRoomToOrder'
import { updateCheckout } from '../../api/updateCheckout'
import { LoadingSpinner } from '../shareable/lottie/LoadingSpinner'
import { addDiscountCodeToOrder } from '../../api/addDiscountCodeToOrder'
import { AddRoomDropDown } from './components/AddRoomDropDown'
import { ButtonPicker } from '../shareable/buttons/ButtonPicker'
import { addOptionToOrder } from '../../api/addOptionToOrder'
import { conceptPrice, guidancePrice } from '../../helpers/config'

const renderKlarna = (htmlSnippet, ref) => {
  const checkoutContainer = ref
  checkoutContainer.innerHTML = htmlSnippet
  const scriptsTags = checkoutContainer.getElementsByTagName('script')
  // This is necessary otherwise the scripts tags are not going to be evaluated
  for (let i = 0; i < scriptsTags.length; i++) {
    const parentNode = scriptsTags[i].parentNode
    const newScriptTag = document.createElement('script')
    newScriptTag.type = 'text/javascript'
    newScriptTag.text = scriptsTags[i].text
    parentNode.removeChild(scriptsTags[i])
    parentNode.appendChild(newScriptTag)
  }
}

const getRoomImg = (room) => {
  if (room === 'arbetsrum') {
    return style1
  } else if (room === 'balkong') {
    return style2
  } else if (room === 'hall') {
    return style3
  } else if (room === 'kök') {
    return style4
  } else if (room === 'konferansrum') {
    return style5
  } else if (room === 'sovrum') {
    return style6
  } else if (room === 'vardagsrum') {
    return style7
  }
}

const Item = ({ room, removeRoom, isLoading, price }) => {
  return (
    <Flex
      mt={4}
      height={'80px'}
      pb={4}
      style={{ borderBottom: '1px solid grey' }}>
      <ImageContain height={'60px'} width={'60px'} src={getRoomImg(room)} />
      <Flex ml={4} jusifyContent={'space-between'} flexDirection={'column'}>
        <Text fontWeight={600} style={{ textTransform: 'capitalize' }}>
          {room}
        </Text>
        <Text>{price}</Text>
      </Flex>
      <Flex flex={1} jusifyContent={'flex-end'}>
        {isLoading ? (
          <Flex width={'50px'} height={'30px'}>
            <LoadingSpinner />
          </Flex>
        ) : (
          <Close
            onClick={removeRoom}
            style={{ cursor: 'pointer' }}
            size={'30px'}
          />
        )}
      </Flex>
    </Flex>
  )
}

const klarnaSpinner = (value) => {
  if (value) {
    window._klarnaCheckout(function(api) {
      api.suspend()
    })
  } else {
    window._klarnaCheckout(function(api) {
      api.resume()
    })
  }
}

const ConceptText = () => {
  return (
    <Text width={'100%'} bg={'grey'} p={4}>
      Efter slutfört köp så kontaktar vår designer dig för att tillsammans gå
      igenom hur din befintliga inredning kan anpassas till den nya.
      <br />
      <br />
      Det som ingår i ett inredningskoncept är: <br />
      <ul style={{ paddingRight: '20px' }}>
        <li>Professionell inredningshjälp.</li>
        <li>Ett 20 min samtal per rum.</li>
        <li>En planskiss över ditt rum.</li>
        <li>En moodboard anpassat för dig.</li>
        <li>En digital shoppinglista.</li>
        <li>Uppdatering av konceptet en vända.</li>
        <li>
          Läs om vår Nöjd Kund-garanti
          <a href={'https://www.hemset.se/faq/'} target='_blank'>
            {' '}
            här
          </a>
          .
        </li>
      </ul>
    </Text>
  )
}

const GuideText = () => {
  return (
    <Text width={'100%'} bg={'grey'} p={4}>
      Efter slutfört köp så kontaktar vår designer dig för att tillsammans gå
      igenom hur din befintliga inredning kan anpassas till den nya.
      <br />
      <br />
      Det som ingår i rådgivning är: <br />
      <ul style={{ paddingRight: '20px' }}>
        <li>Professionell inredningshjälp.</li>
        <li>ett 30 min samtal per rum.</li>
        <li>En digital shoppinglista</li>
        <li>
          Läs om vår Nöjd Kund-garanti
          <a href={'https://www.hemset.se/faq/'} target='_blank'>
            {' '}
            här
          </a>
          .
        </li>
      </ul>
    </Text>
  )
}

export const Basket = ({
  checkout,
  rooms,
  backClick,
  orderId,
  changeRoom,
  changeOption,
  productOption,
  showBackButton = true
}) => {
  const [showDiscount, setShowDiscount] = useState(false)
  const [showAddRoom, setShowAddRoom] = useState(false)
  const [discountCode, setDiscountCode] = useState('')
  const [addDiscountLoading, setAddDiscountLoading] = useState(false)
  const [discountCodeSaved, setDiscountCodeSaved] = useState(false)
  const [removeLoading, setRemoveLoading] = useState(false)
  const klarnaHolderRef = useRef(null)
  useLayoutEffect(() => {
    renderKlarna(checkout.html_snippet, klarnaHolderRef.current)
    // eslint-disable-next-line
  }, [])

  const getBasket = () => {
    return (
      <Flex
        width={'100%'}
        flex={1}
        minHeight={'100%'}
        style={{ position: 'relative', overflow: 'hidden', overflowY: 'auto' }}
        flexDirection={'column'}
        mb={10}>
        <Flex flexDirection={'column'} style={{ position: 'absolute' }}>
          <Flex flexDirection={'column'} width={'100%'}>
            <Flex width={'100%'} jusifyContent={'center'} p={2} mt={[2, 2, 0]}>
              <ButtonPicker
                mb={0}
                titleOne={'Rådgivning'}
                titleTwo={'Koncept'}
                onSelected={async (v) => {
                  setRemoveLoading(true)
                  klarnaSpinner(true)

                  let option = ''
                  if (v === 'one') {
                    //setBudgetType('Rådgivning')
                    option = 'guidance'
                  } else {
                    //setBudgetType('Koncept')
                    option = 'concept'
                  }

                  await addOptionToOrder({ orderId: orderId, option: option })
                  await updateCheckout({
                    orderId: orderId
                  })
                  changeOption(option)
                  setRemoveLoading(false)
                  klarnaSpinner(false)
                }}
                initalOneValue={productOption === 'guidance' ? true : false}
              />
            </Flex>
            <Text fontSize={'22px'}>Varukorg</Text>
            <Flex flexDirection={'column'}>
              {rooms &&
                rooms.map((room, index) => {
                  return (
                    <Item
                      key={index}
                      isLoading={removeLoading}
                      removeRoom={async () => {
                        setRemoveLoading(true)
                        klarnaSpinner(true)
                        const filteredRooms = rooms.filter(
                          (r, i) => i !== index
                        )
                        await addRoomToOrder({
                          orderId: orderId,
                          rooms: filteredRooms
                        })
                        const k = await updateCheckout({
                          orderId: orderId
                        })

                        if (k) {
                          changeRoom(filteredRooms)
                        }
                        setRemoveLoading(false)
                        klarnaSpinner(false)
                      }}
                      price={
                        productOption === 'guidance'
                          ? guidancePrice
                          : conceptPrice
                      }
                      room={room}
                    />
                  )
                })}
            </Flex>
          </Flex>

          <Flex flexDirection={'column'} width={'100%'}>
            <Flex mt={2} mb={4} jusifyContent={'space-between'}>
              <Flex
                onClick={() => {
                  setShowAddRoom(!showAddRoom)
                }}>
                <TextWithClick mr={1} style={{ textDecoration: 'underline' }}>
                  Lägg till ytterligare rum
                </TextWithClick>

                <ChevronDown size={'20px'} />
              </Flex>
            </Flex>
            {showAddRoom && (
              <AddRoomDropDown
                price={
                  productOption === 'guidance' ? guidancePrice : conceptPrice
                }
                addRoom={async (room) => {
                  klarnaSpinner(true)
                  await addRoomToOrder({
                    orderId: orderId,
                    rooms: [...rooms, room]
                  })
                  const k = await updateCheckout({
                    orderId: orderId
                  })

                  if (k) {
                    changeRoom([...rooms, room])
                  }

                  klarnaSpinner(false)
                  setShowAddRoom(false)
                }}
              />
            )}
          </Flex>

          <Flex flexDirection={'column'} width={'100%'}>
            <Flex mt={2} mb={4} jusifyContent={'space-between'}>
              <Flex
                onClick={() => {
                  setShowDiscount(!showDiscount)
                }}>
                <TextWithClick mr={1} style={{ textDecoration: 'underline' }}>
                  Lägg till en presentkod
                </TextWithClick>

                <ChevronDown size={'20px'} />
              </Flex>
            </Flex>
          </Flex>

          {showDiscount && (
            <Flex flexDirection={'column'} width={'100%'}>
              {discountCodeSaved && (
                <Text color={'green'} mb={2}>
                  presentkod tillagd!
                </Text>
              )}
              <Flex mb={4} flexDirection={'column'} pr={2}>
                <Flex>
                  <Input
                    placeholder={'Presentkod'}
                    value={discountCode}
                    onChange={(e) => {
                      setDiscountCode(e.target.value)
                    }}
                  />
                </Flex>
                <Flex mt={2} jusifyContent={'flex-end'}>
                  <Loading isLoading={addDiscountLoading}>
                    <ButtonPrimaryLight
                      onClick={async () => {
                        klarnaSpinner(true)
                        setAddDiscountLoading(true)
                        const c = await addDiscountCodeToOrder({
                          orderId: orderId,
                          discountCode: discountCode
                        })
                        if (c) {
                          setDiscountCodeSaved(true)
                        }
                        setAddDiscountLoading(false)
                        klarnaSpinner(false)
                        setDiscountCode('')
                      }}
                      minWidth={'50px'}>
                      Lägg till
                    </ButtonPrimaryLight>
                  </Loading>
                </Flex>
              </Flex>
            </Flex>
          )}
          <Flex
            width={'100%'}
            flexDirection={'column'}
            height={'150px'}
            style={{ alignSelf: 'flex-end' }}>
            {productOption === 'guidance' ? <GuideText /> : <ConceptText />}
            <Flex mt={4} mb={4}>
              {showBackButton && (
                <ButtonWhite onClick={backClick}>Bakåt</ButtonWhite>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    )
  }
  return (
    <Flex
      width={'100%'}
      flex={1}
      flexDirection={['column', 'column', 'row']}
      style={{ overflowY: 'hidden' }}>
      <Flex
        display={['none', 'none', 'flex']}
        mr={4}
        flex={1}
        flexDirection={'column'}>
        {getBasket()}
      </Flex>

      <Flex
        id={'klarnaholderfixforios'}
        ml={[0, 0, 6]}
        width={['100%', '100%', '340px']}
        flexDirection={'column'}
        alignItems={'center'}
        style={{ position: 'relative', overflow: 'hidden', overflowY: 'auto' }}
        height={['60%', '60%', '100%']}>
        <Flex width={['100%', '100%', '340px']} ref={klarnaHolderRef} />
      </Flex>

      <Flex
        height={'40%'}
        flexDirection={'column'}
        display={['flex', 'flex', 'none']}>
        {getBasket()}
      </Flex>
    </Flex>
  )
}
