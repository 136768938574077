import React, { useState, useRef, useEffect } from 'react'
import { Flex, ImageContain } from '../../primitives'
import logo from '../../../images/hemset-white.png'
import logoBlack from '../../../images/hemset-black.png'
import { Link } from 'gatsby'
import { TextWithClick } from '../text/TextWithHover'
import { TransparentButton } from '../buttons/TransparentButton'
import { fallDown as Menu } from 'react-burger-menu'

const MenuMobile = ({ showMobileScroll, modalOpen }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Flex>
      <Menu isOpen={isOpen}>
        <>
          <Link style={{ textDecoration: 'none' }} to='/hemset'>
            <TextWithClick
              mt={'50px'}
              height={'80px'}
              style={{ textAlign: 'center' }}
              color={'white'}>
              #HEMSET
            </TextWithClick>
          </Link>
          <Link style={{ textDecoration: 'none' }} to='/blog'>
            <TextWithClick
              height={'80px'}
              style={{ textAlign: 'center' }}
              color={'white'}>
              BLOGG
            </TextWithClick>
          </Link>
          <Link style={{ textDecoration: 'none' }} to='/what'>
            <TextWithClick
              height={'80px'}
              style={{ textAlign: 'center' }}
              color={'white'}>
              VAD DU FÅR
            </TextWithClick>
          </Link>
          <Link style={{ textDecoration: 'none' }} to='/how'>
            <TextWithClick
              height={'80px'}
              style={{ textAlign: 'center' }}
              color={'white'}>
              SÅ FUNGERAR DET
            </TextWithClick>
          </Link>
          <Link style={{ textDecoration: 'none' }} to='/about'>
            <TextWithClick
              height={'80px'}
              style={{ textAlign: 'center' }}
              color={'white'}>
              OM OSS
            </TextWithClick>
          </Link>
          <Flex jusifyContent={'center'}>
            <TransparentButton
              onClick={modalOpen}
              border={'1px solid rgba(255, 255, 255, 0.5)'}
              color={'white'}>
              LÄGG BESTÄLLNING
            </TransparentButton>
          </Flex>
        </>
      </Menu>
      <Flex
        display={showMobileScroll ? 'none' : 'flex'}
        width={'100%'}
        height={'60px'}
        bg={'transparent'}
        jusifyContent={'center'}
        style={{
          position: 'fixed',
          zIndex: 15
        }}>
        <Flex
          pr={2}
          pt={'18px'}
          pl={'18px'}
          flex={1}
          bg={'transparent'}
          jusifyContent={'space-between'}
          alignItems={'center'}>
          <Link style={{ textDecoration: 'none' }} to='/'>
            <ImageContain height={'55px'} width={'150px'} src={logoBlack} />
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}
const DesktopMenu = ({ navBackground, modalOpen, blackNav }) => {
  return (
    <Flex
      width={'100%'}
      height={'60px'}
      jusifyContent={'center'}
      style={{
        boxShadow:
          navBackground || blackNav ? '0 2px 2px -2px rgba(0,0,0,.2)' : '',
        background: navBackground || blackNav ? 'white' : 'rgba(0, 0, 0, 0.03)',
        position: 'fixed',
        zIndex: 1337,
        top: navBackground ? 0 : '30.5px'
      }}>
      <Flex
        maxWidth={'1225px'}
        pr={2}
        pl={2}
        flex={1}
        bg={'transparent'}
        jusifyContent={'space-between'}
        alignItems={'center'}>
        <Link style={{ textDecoration: 'none' }} to='/'>
          <ImageContain
            height={'60px'}
            width={'150px'}
            src={navBackground || blackNav ? logoBlack : logoBlack}
          />
        </Link>
        <Link style={{ textDecoration: 'none' }} to='/hemset'>
          <TextWithClick color={navBackground || blackNav ? 'black' : 'black'}>
            #HEMSET
          </TextWithClick>
        </Link>
        <Link style={{ textDecoration: 'none' }} to='/blog'>
          <TextWithClick color={navBackground || blackNav ? 'black' : 'black'}>
            BLOGG
          </TextWithClick>
        </Link>
        <Link style={{ textDecoration: 'none' }} to='/what'>
          <TextWithClick color={navBackground || blackNav ? 'black' : 'black'}>
            VAD DU FÅR
          </TextWithClick>
        </Link>
        <Link style={{ textDecoration: 'none' }} to='/how'>
          <TextWithClick color={navBackground || blackNav ? 'black' : 'black'}>
            SÅ FUNGERAR DET
          </TextWithClick>
        </Link>
        <Link style={{ textDecoration: 'none' }} to='/about'>
          <TextWithClick color={navBackground || blackNav ? 'black' : 'black'}>
            OM OSS
          </TextWithClick>
        </Link>
        <TransparentButton
          onClick={modalOpen}
          border={
            navBackground || blackNav
              ? '1px solid rgba(163, 127,91, 0.5)'
              : '1px solid rgba(163, 127,91, 0.5)'
          }
          color={navBackground || blackNav ? 'black' : 'black'}>
          LÄGG BESTÄLLNING
        </TransparentButton>
      </Flex>
    </Flex>
  )
}

export const Navbar = ({ modalOpen, blackNav }) => {
  const [navBackground, setNavBackground] = useState(false)
  const navRef = useRef()
  const prev = useRef()
  navRef.current = navBackground
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 31
      if (navRef.current !== show) {
        setNavBackground(show)
      }

      prev.current = window.scrollY
    }

    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <Flex display={['flex', 'flex', 'none']}>
        {MenuMobile({
          showMobileScroll: navBackground,
          modalOpen
        })}
      </Flex>
      <Flex display={['none', 'none', 'flex']}>
        {DesktopMenu({ blackNav, navBackground, modalOpen })}
      </Flex>
    </>
  )
}
