import React, { useState } from 'react'
import { Flex, Text } from '../primitives'
import { TextWithClick } from '../shareable/text/TextWithHover'
import { Loading } from '../shareable/loading/loading'
import { startOrder } from '../../api/startOrder'
import { ButtonPrimary } from "../shareable/buttons/ButtonPrimary"

export const Start = ({ orderId, start, close }) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Flex flexDirection={'column'} height={'100%'}>
      <Flex
        width={'100%'}
        height={'100%'}
        flexDirection={'column'}
        alignItems={'center'}
        jusifyContent={'center'}>
        <Text mb={[4, 6, 8]} fontSize={'32px'}>
          Välkommen!
        </Text>
        <Text
          mb={[5, 10, 10]}
          width={'100%'}
          fontSize={3}
          style={{ lineHeight: '26px' }}>
          Du kommer nu gå igenom 6 steg tillsammans med oss. Du börjar med 
          att välja om du är intresserad av rådgivning eller ett helt koncept.
          Sedan väljer du det rum du vill inreda och vilken stil du föredrar. Ladda sedan upp
          dina filer som visar hur rummet ser ut och sätt en fast eller flexibel
          budget. Därefter skickar du din beställning till oss och betalar
          smidigt med faktura eller kort. Luta dig sedan tillbaka och invänta
          att en av våra duktiga inredare kontaktar dig.
        </Text>
        <Loading isLoading={isLoading} mb={4}>
          <ButtonPrimary
            onClick={async () => {
              if (orderId) {
                start(orderId)
              } else {
                setIsLoading(true)
                const data = await startOrder()
                if (data) {
                  start(data.orderId)
                }
              }
            }}
            mb={4}>
            Då sätter vi igång
          </ButtonPrimary>
        </Loading>
        <TextWithClick onClick={close} color={'primaryLight'}>
          Gå tillbaka
        </TextWithClick>
      </Flex>
    </Flex>
  )
}
