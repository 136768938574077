import styled from 'styled-components'
import { Button } from '../../primitives'
import { colors } from '../../../other/colors/color'

const TransparentButton = styled(Button)`
  border: ${(props) => props.border};
  &:hover {
    background-color: ${colors.white};
    color: ${colors.primaryLight};
  }
  &:focus {
    outline: none;
  }
`

TransparentButton.defaultProps = {
  bg: 'transparent',
  color: 'white',
  border: '1px solid rgba(255, 255, 255, 0.5)'
}

export { TransparentButton }



