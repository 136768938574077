import axios from 'axios'

export const getNotPaidOrder = async ({ orderId }) => {
  try {
    const url = process.env.API_URL + '/getNotPaidOrder/?orderId=' + orderId
    const response = await axios({
      method: 'get',
      url: url,
      headers: {
        'content-type': 'application/json'
      }
    })

    console.log('getNotPaidOrder', response.data)

    return response.data
  } catch (e) {
    console.log('erororo', e)
  }
}
