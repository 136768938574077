import React, { useState } from 'react'
import { Flex, Text } from '../primitives'
import { Input } from '../shareable/form/Input'
import { ButtonPrimary } from '../shareable/buttons/ButtonPrimary'
import { Loading } from '../shareable/loading/loading'
import { sendSaveOrderMail } from '../../api/sendSaveOrderMail'

export const SaveOrder = ({orderId}) => {
  const [email, setEmail] = useState('')
  const [sent, setSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Flex flexDirection={'column'} p={[2, 2, 6]}>
      <Text fontWeight={'bold'} fontSize={6} mb={2}>
        Spara dina val?
      </Text>
      {sent ? (
        <Text>Skickat!</Text>
      ) : (
        <>
          <Text fontSize={4}>
            Ange din mail så får du en länk så att du komma tillbaka till dina
            val
          </Text>
          <Flex mt={4}>
            <Input
              placeholder={'E-mail'}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
          </Flex>

          <Flex>
            <Loading isLoading={isLoading}>
              <ButtonPrimary
                mt={4}
                onClick={async () => {
                  if (email) {
                    setIsLoading(true)
                    const s = await sendSaveOrderMail({
                      email: email,
                      link: process.env.WEBSITE_URL + '/?orderId=' + orderId
                    })
                    if (s) {
                      setSent(true)
                    }
                    setIsLoading(false)
                  }
                }}
                type={'submit'}>
                Spara
              </ButtonPrimary>
            </Loading>
          </Flex>
        </>
      )}
    </Flex>
  )
}
