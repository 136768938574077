import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Flex, Text } from '../primitives'
import { PlusSmall } from 'styled-icons/octicons/PlusSmall'
import { BackAndNext } from './components/BackAndNext'
import { Loading } from '../shareable/loading/loading'
import { ButtonPrimaryLight } from '../shareable/buttons/ButtonPrimaryLight'
import { addFilesToOrder } from '../../api/addFilesToOrder'
import { uploadFile } from '../../api/uploadFile'
import { ShowError } from '../../helpers/showError'
import { removeFileFromOrder } from '../../api/removeFileFromOrder'
import { colors } from '../../other/colors/color'
import { AddComment } from './components/AddComment'
import { AddPinterest } from './components/AddPinterest'
import { DeleteForever } from 'styled-icons/material/DeleteForever'
import { getNotPaidOrder } from '../../api/getNotPaidOrder'
const timeout = (ms) => new Promise((res) => setTimeout(res, ms))

const FileItem = ({
  file,
  orderId,
  onRemove,
  startRemoving,
  endRemoving,
  otherLoading
}) => {
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const uploadImage = async () => {
      const response = await addFilesToOrder({
        orderId: orderId,
        files: [file]
      })

      if (response.uploads && response.uploads[0]) {
        try {
          await uploadFile(
            { file: file, url: response.uploads[0].url },
            (percent) => {
              setProgress(percent)
            }
          )
          file.tempId = response.uploads[0]._id
        } catch (e) {
          ShowError({
            message:
              'Kunde inte ladda upp filen ' +
              file.name +
              '. Prova igen lite senare'
          })
        }
      }
    }
    uploadImage()
    // eslint-disable-next-line
  }, [])

  return (
    <Flex
      width={'100%'}
      alignItems={'center'}
      mb={4}
      height={['100px', '100px', 'initial']}
      flexDirection={['column', 'column', 'row']}>
      <Flex flexDirection={'column'} flex={1} mr={2} width={'100%'}>
        <Flex mb={1} jusifyContent={'space-between'}>
          <Text>{file.name}</Text>
          <Text>{progress}%</Text>
        </Flex>
        <Flex height={'3px'} bg={'primaryLight'} width={progress + '%'}></Flex>
      </Flex>
      <Loading width={'100px'} isLoading={loading}>
        <ButtonPrimaryLight
          onClick={async () => {
            if (!otherLoading) {
              setLoading(true)
              startRemoving()
              const r = await removeFileFromOrder({
                orderId: orderId,
                fileId: file.tempId,
                fileName: file.path
              })
              // mongodb can't delete on the same time? need to wait between deletes
              await timeout(500)
              setLoading(false)
              endRemoving()
              if (r) {
                onRemove()
              }
            }
          }}
          style={{
            backgroundColor: otherLoading ? 'lightGrey' : colors.primaryLight,
            minWidth: '100px'
          }}>
          Ta bort
        </ButtonPrimaryLight>
      </Loading>
    </Flex>
  )
}

export const FileStep = ({
  orderId,
  backClick,
  next,
  initial = [],
  comment = null,
  pinterest = null
}) => {
  const [isRemoving, setIsRemoving] = useState(false)
  const [isSureText, setIsSureText] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [files, setFiles] = useState([])
  const [initialFiles, setInitialFiles] = useState(initial)

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles)
      setFiles([...files, ...acceptedFiles])
    },
    // eslint-disable-next-line
    [files]
  )
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <>
      <Flex
        width={'100%'}
        flexDirection={'column'}
        flex={1}
        style={{ overflowY: 'auto', alignContent: 'flex-start' }}>
        <Flex width={'100%'} flexWrap={'wrap'} jusifyContent={'center'}>
          <Text style={{ lineHeight: '1.5' }}>
            Filma gärna långsamt och gå runt i rummet. Se till att takhöjden
            syns! En lagom längd på filmen är 15-20 sekunder. Berätta gärna i
            filmen Vad du tänker kring rummet <br /> <br />
            Lägg gärna till fler bilder för att visa mer detaljer.
            <br />
            <br />
            (Skaffat ny bostad? Prospekt, planritning samt bilder från visning
            går också bra att ladda upp.)
          </Text>

          <Flex width={'100%'} flexDirection={'column'}>
            <Flex width={'100%'} flexDirection={['column', 'row']}>
              <Flex
                mt={4}
                mb={2}
                jusifyContent={'center'}
                style={{
                  cursor: 'pointer',
                  border: '1px solid grey',
                  borderRadius: '5px',
                  outline: 'none'
                }}
                height={'250px'}
                width={'180px'}
                alignItems={'center'}
                flexDirection={'column'}
                {...getRootProps()}>
                <input {...getInputProps()} />
                <Flex color={'grey'}>
                  <PlusSmall size={'100px'} color={'grey'} />
                </Flex>
                <Text mt={6} mb={6} textAlign={'center'}>
                  Lägg till film <br /> eller bild
                </Text>
              </Flex>
              <Flex flexDirection={'column'} flex={1}>
                <AddComment
                  orderId={orderId}
                  commentAlreadySaved={comment ? true : false}
                />
                <AddPinterest
                  orderId={orderId}
                  alreadySaved={pinterest ? true : false}
                />
              </Flex>
            </Flex>
            <Flex flexDirection={'column'} ml={4} mt={4}>
              {files.map((f, index) => {
                return (
                  <FileItem
                    startRemoving={() => {
                      setIsRemoving(true)
                    }}
                    endRemoving={() => {
                      setIsRemoving(false)
                    }}
                    otherLoading={isRemoving}
                    key={index}
                    orderId={orderId}
                    file={f}
                    onRemove={() => {
                      const fls = files.filter((fi) => fi.name !== f.name)
                      setFiles([...fls])
                    }}
                  />
                )
              })}
            </Flex>
            <Flex flex={1} flexDirection={'column'} ml={4} mt={4}>
              {initialFiles.map((f, index) => {
                return (
                  <Flex mb={1} alignItems={'center'} key={index}>
                    <Text mr={2}>{f.origName}</Text>{' '}
                    <DeleteForever
                      size='25px'
                      style={{ color: 'lightGrey', cursor: 'pointer' }}
                      onClick={async () => {
                        const r = await removeFileFromOrder({
                          orderId: orderId,
                          fileId: f._id,
                          fileName: f.name
                        })
                        // mongodb can't delete on the same time? need to wait between deletes
                        await timeout(500)
                        const fls = initialFiles.filter(
                          (fi) => fi.name !== f.name
                        )
                        setInitialFiles([...fls])
                      }}
                    />
                  </Flex>
                )
              })}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {isSureText !== '' && (
        <Flex mt={7} mb={7} jusifyContent={'center'} alignItems={'center'}>
          <Text fontWeight={'600'}>{isSureText}</Text>
        </Flex>
      )}

      <BackAndNext
        canClickNext={
          isSureText !== '' || files.length > 0 || initialFiles.length > 0
        }
        disableClick={() => {
          setIsSureText('Är du säker på att du inte vill skicka in någon bild?')
        }}
        nextTitle={isSureText === '' ? 'Nästa' : 'Ja'}
        isLoading={isLoading}
        backClick={backClick}
        nextClick={async () => {
          setIsLoading(true)
          const data = await getNotPaidOrder({ orderId: orderId })
          setIsLoading(false)
          next(
            data &&
              data.order &&
              data.order.customerOrder &&
              data.order.customerOrder.files
              ? data.order.customerOrder.files
              : []
          )
        }}
      />
    </>
  )
}
