import axios from 'axios'
import { ShowError } from '../helpers/showError'

export const sendSaveOrderMail = async ({ email, link }) => {
  try {
    const url = process.env.API_URL + '/sendSaveOrderMail'
    const response = await axios({
      method: 'post',
      url: url,
      data: {
        email: email,
        link: link
      },
      headers: {
        'content-type': 'application/json'
      }
    })

    return response ? response.data : null
  } catch (e) {
    console.log('erororo', e)
    ShowError({
      message: 'Kunde inte skapa länken. Prova igen lite senare'
    })
  }
}
