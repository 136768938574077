import React, { useState } from 'react'
import { Flex, Text } from '../primitives'
import { Range } from 'react-range'
import { colors } from '../../other/colors/color'
import { TextWithClick } from '../shareable/text/TextWithHover'
import { ButtonPicker } from '../shareable/buttons/ButtonPicker'
import { BackAndNext } from './components/BackAndNext'
import { addBudgetToOrder } from '../../api/addBudgetToOrder'
import { createCheckout } from '../../api/createCheckout'
import { Link } from 'gatsby'

export const Budget = ({
  orderId,
  backClick,
  next,
  rooms,
  initalType,
  initialPrice
}) => {
  const [budgetType, setBudgetType] = useState(
    initalType ? initalType : 'flexibel'
  )
  const [price, setPrice] = useState([initialPrice ? initialPrice : 10000])
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <Flex
        width={'100%'}
        flexWrap={'wrap'}
        flex={1}
        style={{ overflowY: 'auto', alignContent: 'flex-start' }}>
        <Flex width={'100%'} flexDirection={'column'}>
          <Text mb={8}>
            Välj vad du tycker är rimligt att lägga på möbler och dekoration.
          </Text>

          <div>
            <Flex mb={4} jusifyContent={'center'}>
              <Text
                bg={'primary'}
                color={'white'}
                style={{ borderRadius: '25px' }}
                mb={2}
                p={2}
                textAlign={'center'}>
                {price} kr
              </Text>
            </Flex>
          </div>
          <Flex height={'60px'} alignItems={'center'}>
            <Text width={'80px'} mr={2}>
              5000 kr
            </Text>
            <Range
              step={500}
              min={5000}
              max={100000}
              values={price}
              onChange={(values) => setPrice(values)}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '6px',
                    width: '100%',
                    borderRadius: '5px',
                    backgroundColor: '#ccc'
                  }}>
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '25px',
                    width: '25px',
                    borderRadius: '50%',
                    backgroundColor: colors.primary
                  }}
                />
              )}
            />
            <Text width={'100px'} ml={2}>
              100.000 kr
            </Text>
          </Flex>

          <a
            style={{ textDecoration: 'none' }}
            target={'_blank'}
            href={'/hur-man-planerar-sin-inredningsbudget'}>
            <TextWithClick
              color={'secondaryLight'}
              style={{ textDecoration: 'underline' }}
              mt={6}>
              Läs mer om att sätta en budget
            </TextWithClick>
          </a>

          <Text mt={6} fontSize={'22px'}>
            Är din budget fast eller flexibel
          </Text>
          <Text mt={3} mb={5} width={'70%'}>
            Är din budget fast ser vi till att det inte blir mer än vad du tänkt
            dig. Med en flexibel budget kan vi göra vissa utsvävningar med ditt
            godkännande.
          </Text>

          <ButtonPicker
            titleOne={'Fast'}
            titleTwo={'Flexibel'}
            onSelected={(v) => {
              if (v === 'one') {
                setBudgetType('fast')
              } else {
                setBudgetType('flexibel')
              }
            }}
            initalOneValue={budgetType === 'fast' ? true : false}
          />
        </Flex>
      </Flex>

      <BackAndNext
        canClickNext={true}
        isLoading={isLoading}
        backClick={backClick}
        nextClick={async () => {
          setIsLoading(true)
          await addBudgetToOrder({
            orderId: orderId,
            budgetType: budgetType,
            budget: price[0]
          })
          const re = await createCheckout({ rooms, orderId })
          setIsLoading(false)
          if (re && re.checkout) {
            next(re.checkout)
          }
        }}
      />
    </>
  )
}
