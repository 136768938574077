import React, { useState } from 'react'
import { Flex, ImageCover } from '../primitives'
import style1 from '../../images/style_1.jpg'
import style2 from '../../images/style_2.jpg'
import style3 from '../../images/style_3.jpg'
import style4 from '../../images/style_4.jpg'
import style5 from '../../images/style_5.jpg'
import style6 from '../../images/style_6.jpg'
import style7 from '../../images/style_7.jpg'
import style8 from '../../images/style_8.jpg'
import style9 from '../../images/style_9.jpg'
import styled from 'styled-components'
import { CheckCircle } from 'styled-icons/boxicons-solid/CheckCircle'
import { Circle } from 'styled-icons/boxicons-regular/Circle'
import { BackAndNext } from './components/BackAndNext'
import { addWhatAppealToOrder } from '../../api/addWhatAppealToOrder'

const styleImage = { borderRadius: '2px', height: '100%', width: '100%' }

const FlexWithCorner = styled(Flex)`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
  }
  &:before {
    border-width: 1.5em;
    border-right-color: #ccc;
    border-top-color: #ccc;
  }
  &:after {
    border-width: 2em;
    border-bottom-right-radius: ${(props) => (props.selected ? '1px' : '2px')};
    border-right-color: ${(props) => (props.selected ? '#9a7652' : 'white')};
    border-bottom-color: ${(props) => (props.selected ? '#9a7652' : 'white')};
  }
`

const ImageHolder = ({ children, selected, onClick }) => {
  return (
    <Flex width={['100%', '50%', '33%']} p={1}>
      <FlexWithCorner
        onClick={onClick}
        selected={selected}
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        height={'127px'}
        style={{
          cursor: 'pointer',
          border: '1px solid #BABAB1',
          borderRadius: '2px'
        }}>
        {children}
        {selected ? (
          <Flex
            color={'white'}
            style={{
              right: '5px',
              bottom: '10px',
              position: 'absolute',
              zIndex: 12
            }}
            width={'100%'}
            jusifyContent={'flex-end'}>
            <CheckCircle size={'20px'} />
          </Flex>
        ) : (
          <Flex
            color={'lightGrey'}
            style={{
              right: '5px',
              bottom: '10px',
              position: 'absolute',
              zIndex: 12
            }}
            width={'100%'}
            jusifyContent={'flex-end'}>
            <Circle size={'20px'} />
          </Flex>
        )}
      </FlexWithCorner>
    </Flex>
  )
}

export const ChooseFurnishings = ({ orderId, backClick, whatAppeal, next }) => {
  const [selected, setSelected] = useState(whatAppeal ? whatAppeal : [])
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
      <Flex
        width={'100%'}
        flexWrap={'wrap'}
        flex={1}
        style={{ overflowY: 'auto', alignContent: 'flex-start' }}>
        <ImageHolder
          selected={selected.includes('1')}
          onClick={() => {
            if (selected.includes('1')) {
              const s = selected.filter((s) => s !== '1')
              setSelected(s)
            } else {
              setSelected([...selected, '1'])
            }
          }}>
          <ImageCover style={styleImage} src={style1} />
        </ImageHolder>
        <ImageHolder
          selected={selected.includes('2')}
          onClick={() => {
            if (selected.includes('2')) {
              const s = selected.filter((s) => s !== '2')
              setSelected(s)
            } else {
              setSelected([...selected, '2'])
            }
          }}>
          <ImageCover style={styleImage} src={style2} />
        </ImageHolder>
        <ImageHolder
          selected={selected.includes('3')}
          onClick={() => {
            if (selected.includes('3')) {
              const s = selected.filter((s) => s !== '3')
              setSelected(s)
            } else {
              setSelected([...selected, '3'])
            }
          }}>
          <ImageCover style={styleImage} src={style3} />
        </ImageHolder>
        <ImageHolder
          selected={selected.includes('4')}
          onClick={() => {
            if (selected.includes('4')) {
              const s = selected.filter((s) => s !== '4')
              setSelected(s)
            } else {
              setSelected([...selected, '4'])
            }
          }}>
          <ImageCover style={styleImage} src={style4} />
        </ImageHolder>
        <ImageHolder
          selected={selected.includes('5')}
          onClick={() => {
            if (selected.includes('5')) {
              const s = selected.filter((s) => s !== '5')
              setSelected(s)
            } else {
              setSelected([...selected, '5'])
            }
          }}>
          <ImageCover style={styleImage} src={style5} />
        </ImageHolder>
        <ImageHolder
          selected={selected.includes('6')}
          onClick={() => {
            if (selected.includes('6')) {
              const s = selected.filter((s) => s !== '6')
              setSelected(s)
            } else {
              setSelected([...selected, '6'])
            }
          }}>
          <ImageCover style={styleImage} src={style6} />
        </ImageHolder>
        <ImageHolder
          selected={selected.includes('7')}
          onClick={() => {
            if (selected.includes('7')) {
              const s = selected.filter((s) => s !== '7')
              setSelected(s)
            } else {
              setSelected([...selected, '7'])
            }
          }}>
          <ImageCover style={styleImage} src={style7} />
        </ImageHolder>
        <ImageHolder
          selected={selected.includes('8')}
          onClick={() => {
            if (selected.includes('8')) {
              const s = selected.filter((s) => s !== '8')
              setSelected(s)
            } else {
              setSelected([...selected, '8'])
            }
          }}>
          <ImageCover style={styleImage} src={style8} />
        </ImageHolder>
        <ImageHolder
          selected={selected.includes('9')}
          onClick={() => {
            if (selected.includes('9')) {
              const s = selected.filter((s) => s !== '9')
              setSelected(s)
            } else {
              setSelected([...selected, '9'])
            }
          }}>
          <ImageCover style={styleImage} src={style9} />
        </ImageHolder>
      </Flex>
      <BackAndNext
        canClickNext={selected.length > 0}
        isLoading={isLoading}
        backClick={backClick}
        nextClick={async () => {
          if (selected) {
            setIsLoading(true)
            await addWhatAppealToOrder({
              orderId: orderId,
              whatAppeal: selected
            })
            setIsLoading(false)
            next(selected)
          }
        }}
      />
    </>
  )
}
