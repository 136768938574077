import { Flex, Text } from '../../primitives'
import { TextWithClick } from '../../shareable/text/TextWithHover'
import { ChevronDown } from 'styled-icons/evil'
import { Input } from '../../shareable/form/Input'
import { Loading } from '../../shareable/loading/loading'
import { ButtonPrimaryLight } from '../../shareable/buttons/ButtonPrimaryLight'
import { addPinterestToOrder } from '../../../api/addPinterestToOrder'
import React, { useState } from 'react'

export const AddPinterest = ({ orderId, alreadySaved = false }) => {
  //const [showPinterest, setShowPinterest] = useState(true)
  const [addPinterestLoading, setAddPinterestLoading] = useState(false)
  const [pinterest, setPinterest] = useState('')
  const [pinterestIsSaved, setPinterestIsSaved] = useState(alreadySaved)

  return (
    <Flex flexDirection={'column'} p={4}>
      {/*<Flex flexDirection={'column'}>
        <Flex mt={4} mb={4} jusifyContent={'space-between'}>
          <Flex
            onClick={() => {
              setShowPinterest(!showPinterest)
            }}>
            <TextWithClick mr={1} style={{ textDecoration: 'underline' }}>
              Ange pinterest
            </TextWithClick>
            <ChevronDown size={'20px'} />
          </Flex>
        </Flex>
      </Flex>*/}

      <Flex mb={4} flexDirection={'column'}>
        {pinterestIsSaved && <Text mb={2}>Pinterest sparad!</Text>}
        <Flex>
          <Input
            placeholder={'Pinterest'}
            value={pinterest}
            onChange={(e) => {
              setPinterest(e.target.value)
            }}
          />
        </Flex>
        <Flex mt={2} jusifyContent={'flex-end'}>
          <Loading isLoading={addPinterestLoading}>
            <ButtonPrimaryLight
              onClick={async () => {
                setAddPinterestLoading(true)
                const c = await addPinterestToOrder({
                  orderId: orderId,
                  pinterest: pinterest
                })
                if (c) {
                  setPinterestIsSaved(true)
                }
                setAddPinterestLoading(false)
                setPinterest('')
              }}
              minWidth={'50px'}>
              Spara
            </ButtonPrimaryLight>
          </Loading>
        </Flex>
      </Flex>
    </Flex>
  )
}
