import React from 'react'
import { Flex, Text } from '../../primitives'
import styled from 'styled-components'
import { CheckCircle } from 'styled-icons/boxicons-solid'
import { Circle } from 'styled-icons/boxicons-regular'

const FlexWithCorner = styled(Flex)`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    border-color: transparent;
    border-style: solid;
  }
  &:before {
    border-width: 1.5em;
    border-right-color: #ccc;
    border-top-color: #ccc;
  }
  &:after {
    border-width: 2em;
    border-bottom-right-radius: ${(props) => (props.selected ? '1px' : '2px')};
    border-right-color: ${(props) => (props.selected ? '#9a7652' : 'white')};
    border-bottom-color: ${(props) => (props.selected ? '#9a7652' : 'white')};
  }
`

export const OptionCard = ({ title, price, children, selected, onClick }) => {
  return (
    <Flex width={['100%', '100%', '50%']} maxWidth={'290px'} p={1}>
      <FlexWithCorner
        onClick={onClick}
        selected={selected}
        flexDirection={'column'}
        pt={6}
        pb={6}
        pr={2}
        pl={2}
        alignItems={'center'}
        justifyContent={'space-between'}
        width={'100%'}
        height={'450px'}
        style={{
          cursor: 'pointer',
          border: '1px solid #BABAB1',
          borderRadius: '2px'
        }}>
        <Flex flexDirection={'column'}>
          <Text textAlign={'center'} fontSize={7} mb={2}>
            {title}
          </Text>
          {children}
        </Flex>
        <Text color={'primary'} fontSize={7}>
          {price}
        </Text>
        {selected ? (
          <Flex
            color={'white'}
            style={{
              right: '5px',
              bottom: '8px',
              position: 'absolute',
              zIndex: 12
            }}
            width={'100%'}
            jusifyContent={'flex-end'}>
            <CheckCircle size={'20px'} />
          </Flex>
        ) : (
          <Flex
            color={'lightGrey'}
            style={{
              right: '5px',
              bottom: '8px',
              position: 'absolute',
              zIndex: 12
            }}
            width={'100%'}
            jusifyContent={'flex-end'}>
            <Circle size={'20px'} />
          </Flex>
        )}
      </FlexWithCorner>
    </Flex>
  )
}
