import axios from 'axios'

export const uploadFile = async ({ url, file }, procentCallback) => {
  const config = {
    onUploadProgress: function(progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      console.log(percentCompleted)
      procentCallback(percentCompleted)
    }
  }

  const a = await axios.put(url, file, config)

  console.log('akdklasklda', a)
}
