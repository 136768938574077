import React from 'react'
import { Flex, Text } from '../../primitives'
import { TransparentButton } from '../buttons/TransparentButton'
import { Link } from 'gatsby'

export const QuestionMobile = () => {
  return (
    <Flex
      p={2}
      alignItems={'center'}
      bg={'dark'}
      display={['flex', 'flex', 'none']}>
      <Flex
        maxWidth={'1225px'}
        width={'100%'}
        jusifyContent={'space-between'}
        flexDirection={['column', 'column', 'row']}>
        <Flex flex={1} jusifyContent={'center'} alignItems={'center'}>
          <Text
            style={{ textAlign: 'center' }}
            color={'white'}
            mb={2}
            fontSize={[2, 2, 2]}
            fontWeight={600}>
            Har du frågor kring vad vi kan erbjuda?
          </Text>
        </Flex>
        <Flex
          mt={2}
          flex={1}
          flexDirection={'column'}
          alignItems={'center'}
          jusifyContent={'center'}>
          <TransparentButton
            mb={2}
            fontSize={'10px'}
            onClick={() => {
              if (window.Calendly) {
                window.Calendly.showPopupWidget(
                  'https://calendly.com/hemset/boka-ett-samtal?primary_color=798675'
                )
              }
            }}
            height={'35px'}
            border={'1px solid rgb(193, 194, 188)'}
            color={'white'}>
            BOKA ETT SAMTAL MED EMMA
          </TransparentButton>

          <TransparentButton
            mb={2}
            fontSize={'10px'}
            onClick={() => {
              if (window.purechatApi.set) {
                window.purechatApi.set('chatbox.expanded', true)
              }
            }}
            height={'35px'}
            border={'1px solid rgb(193, 194, 188)'}
            color={'white'}>
            CHATTA
          </TransparentButton>

          <Link style={{ textDecoration: 'none' }} to='/faq'>
            <TransparentButton
              color={'white'}
              height={'35px'}
              fontSize={'10px'}
              border={'1px solid rgb(193, 194, 188)'}>
              VANLIGA FRÅGOR & SVAR
            </TransparentButton>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}

export const Question = () => {
  return (
    <Flex
      p={1}
      jusifyContent={'center'}
      alignItems={'center'}
      bg={'dark'}
      display={['none', 'none', 'flex']}>
      <Flex
        maxWidth={'1225px'}
        width={'100%'}
        jusifyContent={'space-between'}
        flexDirection={['column', 'column', 'row']}>
        <Flex flex={1} jusifyContent={'center'} alignItems={'center'}>
          <Text
            style={{ textAlign: 'center' }}
            color={'white'}
            fontSize={[2, 2, 2]}
            fontWeight={400}>
            Har du frågor kring vad vi kan erbjuda?
          </Text>
        </Flex>
        <Flex alignItems={'center'} pr={4}>
          <TransparentButton
            pt={0}
            pb={0}
            fontSize={'10px'}
            height={'20px'}
            onClick={() => {
              if (window.Calendly) {
                window.Calendly.showPopupWidget(
                  'https://calendly.com/hemset/boka-ett-samtal?primary_color=798675'
                )
              }
            }}
            minWidth={'60px'}
            border={'0px solid rgb(193, 194, 188)'}
            color={'white'}>
            BOKA ETT SAMTAL MED EMMA
          </TransparentButton>
          <TransparentButton
            pt={0}
            pb={0}
            fontSize={'10px'}
            height={'20px'}
            onClick={() => {
              if (window.purechatApi.set) {
                window.purechatApi.set('chatbox.expanded', true)
              }
            }}
            minWidth={'60px'}
            border={'0px solid rgb(193, 194, 188)'}
            color={'white'}>
            CHATTA
          </TransparentButton>

          <Link style={{ textDecoration: 'none' }} to='/faq'>
            <TransparentButton
              pt={0}
              minWidth={'150px'}
              width={'10px'}
              color={'white'}
              pb={0}
              fontSize={'10px'}
              height={'20px'}
              border={'0px solid rgb(193, 194, 188)'}>
              VANLIGA FRÅGOR & SVAR
            </TransparentButton>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}
