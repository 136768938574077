import React from 'react'
import { Flex, ImageCover } from '../../primitives'
import icon from '../../../images/icons/cross.svg'
export const CrossButton = ({ onClick, width = '20px', height = '20px' }) => {
  return (
    <Flex style={{ cursor: 'pointer' }} onClick={onClick}>
      <ImageCover src={icon} height={height} width={width} />
    </Flex>
  )
}
